import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { PackageCard, TextInputFixedLabel, PhoneInputField } from "master-react-lib";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';


function GeneralInfo(props) {

    const [phone, setPhone] = React.useState({
        country: "",
        number: "",
    });

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (

        <div className="FormContainer">


            <Grid container spacing={2}>

                <Grid item md={12} sm={12} xs={12}>
                    <h1 className='formTitle'>Member Info</h1>
                </Grid>

                <Grid item xs={12} md={4}>
                    <div className="field_box">
                        <TextInputFixedLabel
                            label="First name"
                            labelStatus={true}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={4}>
                    <div className="field_box">
                        <TextInputFixedLabel
                            label="Middle name"
                            labelStatus={true}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={4}>
                    <div className="field_box">
                        <TextInputFixedLabel
                            label="Last name"
                            labelStatus={true}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={6}>
                    <div className="field_box">
                        <PhoneInputField
                            phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
                            country={'us'}
                            value={phone.number}
                            countryCodeEditable={false}
                            onChange={(phone, country) => console.log("onChange", phone, country)}
                            onBlur={() => console.log("onBlur")}
                            onKeyDown={(e) => console.log("onKeyDown", e.target.value)}
                            variant="outlined"
                            // onlyCountries={["us", "ca", "gb", "my"]}
                            containerStyle={{}}
                            buttonStyle={{}}
                            inputStyle={{
                                marginLeft: "0px",
                                height: "40px",
                                width: "100%",
                                fontFamily: "Roboto-Regular",
                                fontSize: "16px",
                            }}
                            containerClass={{}}
                            errorText={"Phone number is required"}
                            error={false}
                            errorStyles={{ marginBottom: "5px" }}
                            autoFormat={true}
                            disableDropdown={false}
                            disabled={false}
                            disableCountryCode={false}
                            enableSearch={false}
                            disableSearchIcon={false}
                            placeholder={""}
                            inputProps={{}}
                            specialLabel={'Primary Phone'}
                            // preferredCountries={[]}
                            // excludeCountries={[]}
                            labelFixed="Phone number *"
                            LabelStatus={true}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={6}>
                    <div className="field_box">
                        <TextInputFixedLabel
                            label="Email address (Optional)"
                            labelStatus={true}
                        />
                    </div>
                </Grid>



                <Grid item md={12} sm={12} xs={12}>
                    <h1 className='formTitle'>What is your preferred membership?</h1>
                </Grid>

                <Grid item md={12} sm={12} xs={12}>
                    <div className='MembershipCard'>
                        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                            <Tab label={<div><h1>Lifetime Membership</h1><CheckCircleRoundedIcon /></div>} />
                            <Tab label={<div><h1>Premium Membership</h1><p>$100 / Yearly</p><CheckCircleRoundedIcon /></div>} />
                            <Tab label={<div><h1>General Membership</h1><CheckCircleRoundedIcon /></div>} />
                        </Tabs>
                    </div>
                </Grid>

                <Grid item md={12} sm={12} xs={12}>
                    <h2 className='formSubTitle'>Lifetime Membership benefits :</h2>
                </Grid>

                <Grid item md={12} sm={12} xs={12}>
                    <div className='FromList'>
                        <ul>
                            <li>The strategic imperatives of membership organizations.</li>
                            <li>Membership organizations also sponsor industry awards.</li>
                            <li>The Future Of Membership Organizations</li>
                            <li>The strategic imperatives of membership organizations.</li>
                            <li>Membership organizations also sponsor industry awards.</li>
                            <li>The Future Of Membership Organizations</li>
                        </ul>
                    </div>
                </Grid>



            </Grid>


        </div >
    );
}
export default GeneralInfo;


