import React from 'react';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@material-ui/icons/RemoveCircleOutlineRounded';
import '../assets/css/faq.css';

function FaqComp(props) {

    return (

        <div className='FaqMainContainer'>

            <div className='FaqInnerContainer'>
                <Grid container spacing={3}>

                <Grid item md={12} sm={12} xs={12}>
                    <h1 className='formTitle center'>Frequently Asked Questions</h1>
                </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                        <div className='AccordionContainer'>

                            <ExpansionPanel>
                                <ExpansionPanelSummary
                                    expandIcon={
                                        <span className="IconContianer">
                                            <AddCircleOutlineRoundedIcon className="addIcon" />
                                            <RemoveCircleOutlineRoundedIcon className="minusIcon" />
                                        </span>   
                                    }

                                    aria-controls="panel1bh-content" id="panel1bh-header">
                                    <Typography className="accTitle"> What is DEENCONNECT?</Typography>
                                </ExpansionPanelSummary><ExpansionPanelDetails>
                                    <Typography className="accDate">DEENCONNECT is an all-in-one platform for organizations of all sizes to engage members and visitors, and manage day-to-day activities.</Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>

                            <ExpansionPanel>
                                <ExpansionPanelSummary
                                    expandIcon={
                                        <span className="IconContianer">
                                            <AddCircleOutlineRoundedIcon className="addIcon" />
                                            <RemoveCircleOutlineRoundedIcon className="minusIcon" />
                                        </span>   
                                    }
                                    aria-controls="panel1bh-content" id="panel1bh-header">
                                    <Typography className="accTitle"> Who uses DEENCONNECT?</Typography>
                                </ExpansionPanelSummary><ExpansionPanelDetails>
                                    <Typography className="accDate">Engage your members and visitors, and manage your day-to-day activities with all-in-one simple platform to gear up your organization and Masjid.</Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>

                            <ExpansionPanel>
                                <ExpansionPanelSummary
                                    expandIcon={
                                        <span className="IconContianer">
                                            <AddCircleOutlineRoundedIcon className="addIcon" />
                                            <RemoveCircleOutlineRoundedIcon className="minusIcon" />
                                        </span>   
                                    }
                                    aria-controls="panel1bh-content" id="panel1bh-header">
                                    <Typography className="accTitle">How do I contact?</Typography>
                                </ExpansionPanelSummary><ExpansionPanelDetails>
                                    <Typography className="accDate">Glad you asked. Whether you want to refer an organization/Masjid to us,
                                        find out more about what we do, our upcoming services, best way is to visit our website - <a href="https://deenconnect.io/" target="-blanck">deenconnect.io </a> . And if you need bit more attention from us, our direct contact details are also shared.</Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>



                        </div>
                    </Grid>



                </Grid>
            </div >
        </div >
    );
}
export default FaqComp;

