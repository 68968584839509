import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { SelectFieldOne, TextInputFixedLabel, PhoneInputField, InputButton } from "master-react-lib";



function MemberDetails(props) {


    const [phone, setPhone] = React.useState({
        country: "",
        number: "",
    });

    const selectState = [
        { value: 1, select: "New York" },
        { value: 2, select: "California" },
        { value: 3, select: "NY" },
    ];

    const selectgroup = [
        { value: 1, select: "25-30" },
        { value: 2, select: "30-40" },
        { value: 3, select: "40-50" },
        { value: 4, select: "50-60" },
    ];

    const selectGender = [
        { value: 1, select: "Male" },
        { value: 2, select: "Female" },
    ];

    const selectProfession = [
        { value: 1, select: "IT Company" },
        { value: 2, select: "ABC" },
    ];




    const handleChange = (event, value) => {
        console.log(event.target.value, value);
    };

    return (

        <div className="FormContainer">

   


            <Grid container spacing={2}>

                <Grid item md={12} sm={12} xs={12}>
                    <h1 className='formTitle'>Tell us more about yourself</h1>
                </Grid>

                <Grid item xs={12} md={8}>
                    <div className="field_box">
                        <TextInputFixedLabel
                            label="Resident street address"
                            labelStatus={true}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={4}>
                    <div className="field_box">
                        <TextInputFixedLabel
                            label="Apt/Floor (Optional)"
                            labelStatus={true}
                        />
                    </div>
                </Grid>


                <Grid item xs={12} md={4}>
                    <div className="field_box">
                        <TextInputFixedLabel
                            label="City/Town"
                            labelStatus={true}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={4}>
                    <div className="field_box">
                        <SelectFieldOne
                            label="State/Province"
                            selectdata={selectState}
                            defaultValue={1}
                            handleChange={handleChange}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={4}>
                    <div className="field_box">
                        <TextInputFixedLabel
                            label="Zip/Postal code"
                            labelStatus={true}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={12}>
                    <div className="field_box">
                        <PhoneInputField
                            phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
                            country={'us'}
                            value={phone.number}
                            countryCodeEditable={false}
                            onChange={(phone, country) => console.log("onChange", phone, country)}
                            onBlur={() => console.log("onBlur")}
                            onKeyDown={(e) => console.log("onKeyDown", e.target.value)}
                            variant="outlined"
                            // onlyCountries={["us", "ca", "gb", "my"]}
                            containerStyle={{}}
                            buttonStyle={{}}
                            inputStyle={{
                                marginLeft: "0px",
                                height: "40px",
                                width: "100%",
                                fontFamily: "Roboto-Regular",
                                fontSize: "16px",
                            }}
                            containerClass={{}}
                            errorText={"Phone number is required"}
                            error={false}
                            errorStyles={{ marginBottom: "5px" }}
                            autoFormat={true}
                            disableDropdown={false}
                            disabled={false}
                            disableCountryCode={false}
                            enableSearch={false}
                            disableSearchIcon={false}
                            placeholder={""}
                            inputProps={{}}
                            specialLabel={'Primary Phone'}
                            // preferredCountries={[]}
                            // excludeCountries={[]}
                            labelFixed="Country"
                            LabelStatus={true}
                        />
                    </div>
                </Grid>


                <Grid item xs={12} md={4}>
                    <div className="field_box">
                        <SelectFieldOne
                            label="Age group"
                            selectdata={selectgroup}
                            defaultValue={1}
                            handleChange={handleChange}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={4}>
                    <div className="field_box">
                        <SelectFieldOne
                            label="Gender"
                            selectdata={selectGender}
                            defaultValue={1}
                            handleChange={handleChange}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={4}>
                    <div className="field_box">
                        <SelectFieldOne
                            label="Race"
                            selectdata={selectState}
                            defaultValue={1}
                            handleChange={handleChange}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={12}>
                    <div className="field_box">
                        <SelectFieldOne
                            label="Profession"
                            selectdata={selectProfession}
                            defaultValue={1}
                            handleChange={handleChange}
                        />
                    </div>
                </Grid>


            </Grid>


        </div >
    );
}
export default MemberDetails;


