
import React from 'react'
import { FooterBottomOne } from "master-react-lib";
import Grid from '@material-ui/core/Grid';
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';
import tickIcon from '../assets/imgs/checkIcon.svg'
import '../assets/css/Footer.css';

function Footer() {
    return (

        <>
            <div className="ExpansionFooterContainer">


                <div className="ExpansionContainer">
                    <Grid container spacing={3}>

                        <Grid item md={4} sm={4} xs={12}>
                            <div className="FooterTop">
                                <h1>Organization Full Name</h1>
                                <p>We are 501(c)(3) tax-exempted organization.</p>
                                <p>Tax ID 99-9090909 <img className="tickIcon" src={tickIcon} /> </p>
                            </div>
                        </Grid>

                        <Grid item md={4} sm={4} xs={12}>
                            <div className="FooterTop">
                                <h1>Address</h1>
                                <p>167 East 43rd ST</p>
                                <p>Brooklyn, NY 11203</p>
                            </div>
                        </Grid>

                        <Grid item md={4} sm={4} xs={12}>
                            <div className="FooterTop">
                                <h1>Contact</h1>
                                <p><PhoneRoundedIcon />+1 (718) 306-7288</p>
                                <p><EmailRoundedIcon />baitussalam167@gmail.com</p>
                            </div>
                        </Grid>

                    </Grid>

                </div>

            </div>

            <FooterBottomOne
                bordercolor="#56B149"
                Container="1170px"

            />
        </>

    );
}










export default Footer