import React from 'react';
import Header from './Header'
import PageTitleOneCom from './PageTitle'
import StepperComp from './Stepper'
import FaqComp from './Faq'
import Footer from './Footer'

import '../assets/css/style.css'

function HomePage(props) {



  return (

    <div>
      <Header />
      <PageTitleOneCom />
      <StepperComp />
      <FaqComp />
      <Footer />
    </div>

  );
}


export default HomePage;