import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import Container from '@material-ui/core/Container';
//=== Forms ====//
import GeneralInfo from './GeneralInfo'
import MemberDetails from './MemberDetails'
import EmergencyContact from './EmergencyContact'


import '../assets/css/Stepper.css';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    completed: {
        display: 'inline-block',
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function getSteps() {
    return ['General Info', 'Member Details', 'Emergency Contact', 'Checkout'];
}

function getStepContent(step) {
    switch (step) {
        case 0:
            return (<GeneralInfo />);
        case 1:
            return (<MemberDetails />);
        case 2:
            return (<EmergencyContact />);
        case 3:
            return 'Step 3: This is the bit I really care about!';

        default:
            return 'Unknown step';
    }
}


function StepperComp() {

    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    const steps = getSteps();

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };



    return (
        <div className='StapperMainContainer'>

            <div className="StepperHeader">
                <Stepper nonLinear activeStep={activeStep}>
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepButton onClick={handleStep(index)} completed={completed[index]}>
                                {label}
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>
            </div>

            <div>
                {allStepsCompleted() ? (
                    <div>
                        <Typography className={classes.instructions}>
                            All steps completed - you&apos;re finished
                        </Typography>
                        <Button onClick={handleReset}>Reset</Button>
                    </div>
                ) : (


                    <div className="FormBody">
                        <Container maxWidth="md">
                        <Typography className="instructions">{getStepContent(activeStep)}</Typography>

                        <div className="FormButtonBody">

                            {activeStep !== 0 &&
                                <Button onClick={handleBack} className="buttonBack">
                                    <ArrowBackIosRoundedIcon /> GO BACK
                                </Button>
                            }
                            <span></span>
                            {/* <Button
                                variant="contained"
                                color="primary"
                                onClick={handleNext}
                                className={classes.button}
                            >
                                Next
                            </Button> */}
                            {activeStep !== steps.length &&
                                (completed[activeStep] ? (
                                    <Typography variant="caption" className="completed">
                                        Step {activeStep + 1} already completed
                                    </Typography>
                                ) : (
                                    <Button variant="contained" color="primary" onClick={handleComplete}>
                                        {completedSteps() === totalSteps() - 1 ? 'ACCEPT INVITATION' : 'CONTINUE'}
                                    </Button>
                                ))}
                        </div>
                        </Container>
                    </div>
                )}
            </div>

        </div>
    );
}


export default StepperComp;