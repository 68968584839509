import React from 'react';
import { PageTitleOne  } from "master-react-lib";
import Pattern from "../assets/imgs/./Pattern.svg";


function PageTitleOneCom() {



    return (
        <div>
            <PageTitleOne
                Title="Membership Form"
                SubTitle="Masjid Baitus Salam"
                color="#fff"
                backgroundcolor="#56B149"
                backgroundPattern={Pattern}
            />

        </div>
    );
}


export default PageTitleOneCom;